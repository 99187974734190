import { SignIn } from "@clerk/remix";
import { $path } from "remix-routes";

export default function LoginPage() {
	return (
		<div className="flex flex-1 items-center justify-center bg-accent/50 p-4 dark:bg-accent/25 md:p-8">
			<SignIn
				forceRedirectUrl={$path("/", {
					action: "login",
				})}
				path="/login"
			/>
		</div>
	);
}
